import React from 'react';
import { Menu, notification } from 'antd';
import { reduxForm, Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../../common/custom-input';
import { Strings } from '../../../../dataProvider/localize';
import { CustomDatepicker } from '../../../common/customDatepicker';
import moment from 'moment';
import { IconButton } from "@mui/material";
import { CustomSingleSelect } from "../../../common/new-core-ui/CustomSingleSelect";
import { ADMIN_DETAILS, VALIDATE_STATUS, ACCESS_CONTROL } from '../../../../dataProvider/constant';
import { isNumber } from '../../../../utils/Validations/scopeDocValidation';
import { countryCodes } from '../../../../dataProvider/countryCodes'
import { handleFocus, DeepTrim } from '../../../../utils/common';
import * as actions from '../../../../actions/profileManagementActions';
import { Link } from 'react-router-dom';
import { getStorage, setStorage } from '../../../../utils/common';
import { personalDetailsForm } from '../../../../utils/Validations/personalDetailsFormValidation';
import { createCanvas, loadImage } from 'canvas';
import colourStyles from "../FilterSelectProfileStyles";
import { components } from "react-select";
import PhoneDropdownStyle from "../PhoneDropdownStyles";
import "../ProfileStyle.css";
import styles from "../ProfileStyles.module.css";
import ManagerDetails from "../ManagerDetails/ManagerDetails";
import {
    loadModels,
    getFullFaceDescription,
} from '../../../../dataProvider/face';
import UserTimeZone from '../UserTimeZone/UserTimeZone';

const INIT_STATE = {
    url: null,
    imageURL: null,
    fullDesc: null,
    imageDimension: null,
    error: null,
    loading: false
};

class PesonalDetailsForm extends React.Component {
    gender = ['Male', 'Female', 'Other']

    constructor(props) {
        super(props);
        this.state = {
            date: '',
            phnOtpErr: false,
            emailOtpErr: false,
            fileList: [],
            fileRemoved: false,
            hasEditPermission: false
        }

        this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
        this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
        this.shouldUpdateAuthUser = this.props.isFromUserManagement && this.props.isFromUserManagement === 'isFromUserMgmt' ? true : false
    }

    static getDerivedStateFromProps(props, state) {
        var OrgSACountry = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).country : null;
        if (!props.formValues && props.formValues.ec_country || props.formValues && props.formValues.ec_country === '') {
            props.change(`ec_country`, OrgSACountry);
        }
    }

    componentDidMount() {
        if (this.hasPermission("sf_personal_details_controller_update_personal_details")) {
            this.setState({ hasEditPermission: true });
        }
    }

    onSubmit = async (formData) => {
        formData = await DeepTrim(formData);
        formData = {
            ...formData,
            'profile_progress': this.props.profileComplete,
            country_code: formData.country_code && formData.country_code.value ? formData.country_code.value : null,
            gender: formData.gender && formData.gender.value ? formData.gender.value : null,
            date_of_birth: formData.date_of_birth ? moment(formData.date_of_birth).format("YYYY-MM-DD") : null,
        }
        delete formData.isDirty

        let finalFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'student_usi') {
                if (formData[key]) {
                    finalFormData.append(key, formData[key]);
                }
            } else if (key === 'zone') {
                if (formData[key]) {
                    finalFormData.append(key, formData[key]);
                }
            }
            else {
                finalFormData.append(key, formData[key]);
            }
        });
        finalFormData.delete('profile_pic')
        let staffUserName = false
        if (this.props.userDetails) {
            staffUserName = this.props.userDetails.user_name
        }
        this.props.action.updateOrgUserPersonalDetails(finalFormData, staffUserName)
            .then((flag) => {
                notification.success({
                    message: Strings.success_title,
                    description: flag,
                    onClick: () => { },
                    className: 'ant-success'
                });
                this.props.action.getOrgUserProfileDetails(staffUserName ? this.props.userDetails.user_id : this.org_user_id, staffUserName ? this.props.userDetails.user_name : this.org_user_name, null, this.shouldUpdateAuthUser)
                    .then(() => {
                        this.setState({
                            fileRemoved: false
                        })
                        if (staffUserName) {
                            return
                        } else {
                            setStorage(ADMIN_DETAILS, JSON.stringify({
                                ...JSON.parse(getStorage(ADMIN_DETAILS)),
                                name: this.props && this.props.profile && this.props.profile[0] && this.props.profile[0].name ?
                                    this.props.profile[0].name : JSON.parse(getStorage(ADMIN_DETAILS)).name,
                                last_name: this.props && this.props.profile && this.props.profile[0] && this.props.profile[0].last_name ?
                                    this.props.profile[0].last_name : JSON.parse(getStorage(ADMIN_DETAILS)).last_name
                            }))
                        };
                    });
            })
            .catch((error) => {
                if (error.status === VALIDATE_STATUS) {
                    notification.warning({
                        message: Strings.validate_title,
                        description: error && error.data && typeof error.data.message == 'string'
                            ? error.data.message : Strings.generic_validate,
                        onClick: () => { },
                        className: 'ant-warning'
                    });
                } else {
                    notification.error({
                        message: Strings.error_title,
                        description: error && error.data && error.data.message && typeof error.data.message == 'string'
                            ? error.data.message : Strings.generic_error,
                        onClick: () => { },
                        className: 'ant-error'
                    });
                }
            });
    }

    handleVerifyPhn = () => {
        const phnCode = this.props.formValues.phone_code
        if (phnCode) {
            this.props.action.verifyAttr({ phone_code: phnCode })
                .then(message => {
                    this.setState({
                        phnVerify: 'none'
                    })
                    notification.success({
                        message: Strings.success_title,
                        description: message ? message : Strings.generic_error, onClick: () => { },
                        className: 'ant-success'
                    })
                    this.props.action.getOrgUserProfileDetails(this.org_user_id, this.org_user_namenull, this.shouldUpdateAuthUser);
                }).catch((error) => {
                    if (error.status === VALIDATE_STATUS) {
                        notification.warning({
                            message: Strings.validate_title,
                            description: error && error.data && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_validate, onClick: () => { },
                            className: 'ant-warning'
                        });
                    } else {
                        notification.error({
                            message: Strings.error_title,
                            description: error && error.data && error.data.message && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_error, onClick: () => { },
                            className: 'ant-error'
                        });
                    }
                });
        } else {
            this.setState({
                phnOtpErr: true
            })
        }
    }

    handleVerifyEmail = () => {
        const emailCode = this.props.formValues.email_code
        if (emailCode) {
            this.props.action.verifyAttr({ email_code: emailCode })
                .then(message => {
                    this.setState({
                        emailVerify: 'none'
                    })
                    notification.success({
                        message: Strings.success_title,
                        description: message ? message : Strings.generic_error, onClick: () => { },
                        className: 'ant-success'
                    })
                    this.props.action.getOrgUserDetails(this.org_user_id, this.org_user_name, null, this.shouldUpdateAuthUser);
                }).catch((error) => {
                    if (error.status === VALIDATE_STATUS) {
                        notification.warning({
                            message: Strings.validate_title,
                            description: error && error.data && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_validate, onClick: () => { },
                            className: 'ant-warning'
                        });
                    } else {
                        notification.error({
                            message: Strings.error_title,
                            description: error && error.data && error.data.message && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_error, onClick: () => { },
                            className: 'ant-error'
                        });
                    }
                });
        } else {
            this.setState({
                emailOtpErr: true
            })
        }
    }

    resendOtpPhn = val => {
        this.props.action.resendVerifyAttr(val)
            .then(message => {
                notification.success({
                    message: Strings.success_title,
                    description: message ? message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-success'
                })
            }).catch(message => {
                notification.error({
                    message: Strings.error_title,
                    description: message ? message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-error'
                })
            })
    }

    resendOtpEmail = val => {
        this.props.action.resendVerifyAttr(val)
            .then(message => {
                notification.success({
                    message: Strings.success_title,
                    description: message ? message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-success'
                })
            }).catch(message => {
                notification.error({
                    message: Strings.error_title,
                    description: message ? message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-error'
                })
            })
    }

    disabledDate = (current) => {
        return current && current.valueOf() > Date.now();
    }

    resetState = () => {
        this.setState({ ...INIT_STATE });
    };

    mounting = async () => {
        await loadModels();
    };

    // profile pic loader
    profileLoaderView = (
        <div className="profile-loader">
            <div class="sk-circle">
                <div class="sk-circle1 sk-child"></div>
                <div class="sk-circle2 sk-child"></div>
                <div class="sk-circle3 sk-child"></div>
                <div class="sk-circle4 sk-child"></div>
                <div class="sk-circle5 sk-child"></div>
                <div class="sk-circle6 sk-child"></div>
                <div class="sk-circle7 sk-child"></div>
                <div class="sk-circle8 sk-child"></div>
                <div class="sk-circle9 sk-child"></div>
                <div class="sk-circle10 sk-child"></div>
                <div class="sk-circle11 sk-child"></div>
                <div class="sk-circle12 sk-child"></div>
            </div>
        </div>
    )

    handleFileChange = async file => {
        this.resetState();
        await this.setState({
            imageURL: URL.createObjectURL(file),
            loading: true
        });
        await this.handleImageChange();
    };

    getImageDimension = imageURL => {
        let img = new Image();
        img.src = imageURL;
        img.onload = () => {
            let HEIGHT = (this.state.WIDTH * img.height) / img.width;
            this.setState({
                HEIGHT,
                imageDimension: {
                    width: img.width,
                    height: img.height
                }
            });
        };
    };

    handleImageChange = async (image = this.state.imageURL) => {
        await this.getImageDimension(image);
        await getFullFaceDescription(image).then(fullDesc => {
            if (fullDesc.length > 1 || fullDesc.length === 0) {
                notification.error({
                    message: Strings.error_title,
                    description: 'Invalid Profile Photo!',
                    onClick: () => { },
                    className: 'ant-error'
                });
                this.setState({ uploadLoader: false });
                return;
            } else if (this.state.imageDimension.width < 150 && this.state.imageDimension.height < 150) {
                notification.error({
                    message: Strings.error_title,
                    description: 'Upload Profile Photo with size of 150 X 150 px',
                    onClick: () => { },
                    className: 'ant-error'
                });
                this.setState({ uploadLoader: false });
                return;
            } else {
                const canvas = createCanvas(this.state.imageDimension.width, this.state.imageDimension.height)
                const ctx = canvas.getContext('2d');


                var imageURL = ''
                loadImage(this.state.imageURL).then((image1) => {
                    ctx.shadowColor = '#000';
                    ctx.shadowBlur = 20;
                    ctx.shadowOffsetX = 0;
                    ctx.shadowOffsetY = 0;

                    // no crop
                    let cropParam = this.cropBoxDimention(fullDesc[0].detection, this.state.imageDimension.width, this.state.imageDimension.height)

                    // elementToDraw, cropX, cropY, width, height, x, y, width, height
                    //ctx.drawImage(image1, cropParam.cropX, cropParam.cropY, cropParam.cropWidth, cropParam.cropHeight, 0, 0, this.state.WIDTH, this.state.WIDTH);

                    // cropped
                    ctx.drawImage(image1, cropParam.cropX, cropParam.cropY, cropParam.cropWidth, cropParam.cropHeight, 0, 0, cropParam.cropWidth, cropParam.cropHeight);

                    imageURL = canvas.toDataURL()

                    loadImage(imageURL).then((image2) => {
                        const canvas2 = createCanvas(cropParam.cropWidth, cropParam.cropHeight)
                        const ctx2 = canvas2.getContext('2d');
                        ctx2.shadowColor = '#000';
                        ctx2.shadowBlur = 20;
                        ctx2.shadowOffsetX = 0;
                        ctx2.shadowOffsetY = 0;
                        ctx2.drawImage(image2, 0, 0, cropParam.cropWidth, cropParam.cropHeight, 0, 0, cropParam.cropWidth, cropParam.cropHeight);
                        imageURL = canvas2.toDataURL()
                        this.setState({ imageURL });
                        this.props.change('profile_pic', imageURL)
                        this.props.change('isDirty', true)
                        // this.uploadProfImage()
                    });


                }).catch((error) => {
                    console.log(error);
                });
                this.setState({ fullDesc, uploadLoader: false });
            }
        });
    };

    cropBoxDimention = (detection, imageWidth, imageHeight) => {
        const relativeBox = detection.relativeBox;
        const dimension = detection._imageDims;

        let _X = imageWidth * relativeBox._x;
        let _Y = (relativeBox._y * imageWidth * dimension._height) / dimension._width;
        let _W = imageWidth * relativeBox.width;
        let _H = (relativeBox.height * imageHeight * dimension._height) /
            dimension._width;
        //return { cropX: X, cropY: Y, cropWidth: W, cropHeight: H }

        return {
            cropX: _X - (_X * 0.33), cropY: _Y - (_Y),
            cropWidth: _W + (_X * 0.66), cropHeight: _H + (_Y * 2)
        }
    }

    removeFile = () => this.setState({ file: [], logoImageURL: '' });

    removeVisaDoc = () => {
        this.setState({
            fileRemoved: true,
        })
    }

    hasPermission = (permission) => {
        let allowed = false;
        let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
        let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
        let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
        if (permission_id !== -1 || this.props.location.pathname === "/dashboard/profile") {
            allowed = true;
        }
        return allowed;
    }

    DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={20} width={20} fill="currentColor">
                    <path d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
            </components.DropdownIndicator>
        );
    };

    render() {

        const uploadPicProps = {
            beforeUpload: file => {
                this.setState({
                    file: [file],
                });
                return false;
            },
            multiple: false,
            onChange: (info) => {
                this.props.change(`isDirty`, false)
            },
            accept: ".jpeg,.jpg,.png,.pdf",
            fileList: this.state.file,
            onRemove: this.removeFile
        };

        const { handleSubmit, initialValues, formValues } = this.props;
        let visa_doc_file_path;
        if (initialValues && initialValues.visa_doc && this.state.fileRemoved) {
            visa_doc_file_path = null;
        } else {
            visa_doc_file_path = initialValues && initialValues.visa_doc ? initialValues.visa_doc : null
        }

        let roleSlug = getStorage(ADMIN_DETAILS) && JSON.parse(getStorage(ADMIN_DETAILS)).role.slug;

        const editMenu = (
            <Menu>
                <Menu.Item></Menu.Item>
            </Menu>
        )

        const props = {
            name: 'file',
            accept: ".jpeg,.jpg,.png",
            // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            // fileList: [this.state.file],
            showUploadList: false,
            beforeUpload: file => {
                this.setState({ uploadLoader: true })
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                const isLt5M = file.size / 1024 / 1024 < 2;

                if (!isJpgOrPng) {
                    this.setState({ uploadLoader: false })
                    notification.error({
                        message: Strings.error_title,
                        description: 'You can only upload JPG/PNG file!',
                        onClick: () => { },
                        className: 'ant-error'
                    });
                } else if (!isLt5M) {
                    this.setState({ uploadLoader: false })
                    notification.error({
                        message: Strings.error_title,
                        description: 'Image must smaller than 2MB!',
                        onClick: () => { },
                        className: 'ant-error'
                    });
                } else {
                    const start = async () => {
                        this.mounting()
                        await this.handleFileChange(file)
                    }
                    Promise.all([
                        this.mounting()
                    ]).then(start)
                }
                return false;
            },
            onChange: info => {
                if (info.file.status !== 'uploading') {
                }
                /*  if (info.file.status === 'done') {
                     message.success(`${info.file.name} file uploaded successfully`);
                     getBase64(info.file.originFileObj, imageUrl =>
                         this.setState({
                             file: imageUrl,
                         }),
                     );
                 } else if (info.file.status === 'error') {
                     message.error(`${info.file.name} file upload failed.`);
                 } */
            },
            onRemove: this.removeFile
        };

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                {/* Personal Details */}
                <hr className={styles.DetailsDivider} />
                <div className="container-fluid">
                    <UserTimeZone
                        userDetails={this.props.userDetails}
                        onReloadUser={() => this.props.action.getOrgUserDetails(this.props.userDetails ? this.props.userDetails.organisation_id : this.org_user_id, this.props.userDetails ? this.props.userDetails.user_name : this.org_user_name, null, this.shouldUpdateAuthUser)} />
                    <ManagerDetails
                        userDetails={this.props.userDetails}
                        onClose={() => this.props.action.getOrgUserProfileDetails(this.props.userDetails ? this.props.userDetails.organisation_id : this.org_user_id, this.props.userDetails ? this.props.userDetails.user_name : this.org_user_name, null, this.shouldUpdateAuthUser)} />
                    <div className="row align-items-center">
                        <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Personal Details</h2>
                    </div>
                    {/* <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-6 col-sm-12 text-center" >
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#222", }}>Profile Picture</label>
                            <div className="">
                                <div className="profile-pic" style={{ color: '#03a791', border: '4px solid #03a791' }}>
                                    {this.state.uploadLoader ?
                                        this.profileLoaderView
                                        : ''
                                    }
                                    {initialValues && initialValues.profile_pic ?
                                        <img src={initialValues.profile_pic} />
                                        :
                                        <Icon type="camera" theme="filled" />
                                    }
                                    <Upload className="upload-pic temp-upload-pic" id="profile_pic" {...props}>
                                        {this.state.imageURL ? <span className="temp-pic-view"><img src={formValues.profile_pic} /></span> : ''}
                                        <Button disabled={!this.state.hasEditPermission}><i class="fa fa-pencil"></i></Button>
                                    </Upload>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>First Name</div>
                            <div>
                                <Field
                                    name="name"
                                    placeholder={Strings.name_acc_pd}
                                    type="text"
                                    id="name"
                                    className={styles.ProfileFormField}
                                    autoComplete="off"
                                    component={customInput}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Middle Name</div>
                            <div>
                                <Field
                                    name="middle_name"
                                    placeholder={Strings.middle_name_acc_pd}
                                    className={styles.ProfileFormField}
                                    type="text"
                                    autoComplete="off"
                                    id="name"
                                    component={customInput}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Last Name</div>
                            <div>
                                <Field
                                    name="last_name"
                                    placeholder={Strings.last_name_acc_pd}
                                    className={styles.ProfileFormField}
                                    type="text"
                                    id="last_name"
                                    autoComplete="off"
                                    component={customInput}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={styles.ProfileLabelV2}>Date of Birth</div>
                            <div>
                                <Field
                                    name="date_of_birth"
                                    id="date_of_birth"
                                    disabledDate={this.disabledDate}
                                    component={CustomDatepicker}
                                    className="profile-v2-date-picker"
                                    dropdownClassName="cs-datepicker-dropdown"
                                    disabled={!this.state.hasEditPermission}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Gender</div>
                            <div>
                                <Field
                                    name="gender"
                                    type="text"
                                    id="gender"
                                    options={this.gender.map((gen) => ({
                                        label: gen,
                                        value: gen,
                                    }))}
                                    component={CustomSingleSelect}
                                    styles={colourStyles}
                                    otherProps={{
                                        components: { dropdownIndicator: <components.DownChevron /> },
                                        isDisabled: !this.state.hasEditPermission
                                    }} />
                            </div>
                        </div>

                        <div className="col-3 border-right">
                            <div className="sf-form">
                                <div className={styles.ProfileLabelV2}>Mobile Number</div>
                                <div className="co-code-mobile-no">
                                    <div className="d-flex w-100">
                                        <div style={{ flex: "0 0 70px" }}>
                                            <Field name="country_code" options={countryCodes.map(country => ({
                                                label: country.dial_code, value: country.dial_code
                                            }))} component={CustomSingleSelect} otherProps={{
                                                styles: PhoneDropdownStyle, placeholder: "",
                                                components: { DropdownIndicator: this.DropdownIndicator }
                                            }} />
                                        </div>
                                        <div style={{ flex: "1 1 auto" }}>
                                            <Field name="phone_number" type="text" id="phone" className={`${styles.InputFieldV2} ${styles.CustomPhone}`}
                                                placeholder="Mobile Number" component={customInput} />
                                        </div>
                                    </div>
                                </div>
                                {
                                    initialValues && initialValues.phone_verified === 0
                                        ? <div className="no-varify-form">
                                            <div>
                                                <Field
                                                    name="phone_code"
                                                    placeholder="Enter OTP here."
                                                    type="text"
                                                    validate={isNumber}
                                                    id="phone_code"
                                                    className={styles.ProfileFormField}
                                                    component={customInput}
                                                    disabled={!this.state.hasEditPermission} />
                                            </div>
                                            <button type="button" disabled={!this.state.hasEditPermission} className="bnt bnt-active" onClick={this.handleVerifyPhn}>Verify</button>
                                            {
                                                this.state.phnOtpErr
                                                    ? <span class="error-input">This is a required field</span>
                                                    : ''
                                            }
                                            <Link className="re-send-bnt" onClick={(val) => this.resendOtpPhn(val = 'PHONE')}>Resend OTP</Link>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                        {roleSlug !== "STAFF" ?
                            < div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Phone Number</div>
                                <div>
                                    <Field
                                        name="contact_number"
                                        type="text"
                                        id="contact_number"
                                        className={styles.ProfileFormField}
                                        component={customInput}
                                        disabled={!this.state.hasEditPermission} />
                                </div>
                            </div> :
                            <div></div>
                        }

                        <div className="col-3">
                            <div className={styles.ProfileLabelV2}>Primary Email</div>
                            <div>
                                <Field
                                    name="email_address"
                                    placeholder={Strings.email_address_acc_pd}
                                    type="text"
                                    id="email_address"
                                    className={styles.ProfileFormField}
                                    component={customInput}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                            {initialValues && initialValues.email_verified === 0 ?
                                <div className="no-varify-form">
                                    <div>
                                        <Field
                                            name="email_code"
                                            placeholder="Enter OTP here."
                                            type="text"
                                            id="email_code"
                                            validate={isNumber}
                                            className={styles.ProfileFormField}
                                            component={customInput}
                                            disabled={!this.state.hasEditPermission} />
                                    </div>
                                    <button disabled={!this.state.hasEditPermission} type="button" className="bnt bnt-active" onClick={this.handleVerifyEmail}>Verify</button>
                                    {this.state.emailOtpErr ?
                                        <span class="error-input">This is a required field</span>
                                        :
                                        ''
                                    }
                                    <Link className="re-send-bnt" onClick={(val) => this.resendOtpEmail(val = 'EMAIL')}>Resend OTP</Link>
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 border-right">
                            <div className={styles.ProfileLabelV2}>Student USI</div>
                            <div>
                                <Field
                                    name="student_usi"
                                    type="text"
                                    placeholder={Strings.student_usi}
                                    id="student_usi"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Visa Number</div>
                            <div>
                                <Field
                                    name="visa_number"
                                    type="text"
                                    placeholder={Strings.visa_number}
                                    id="visa_number"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <button type="submit" className={styles.BtnSaveTab} disabled={!this.props.isDirty || !this.state.hasEditPermission}>
                            Save
                        </button>
                    </div>
                </div>
            </form >
        );
    }
}

const mapStateToProps = (state) => {
    let value = {}
    if (state.profileManagement && state.profileManagement.profile) {
        for (let item of state.profileManagement.profile) {
            if (item.profile_pic) {
                value = {
                    id: item.id, name: item.name ? item.name : '', last_name: item.last_name ? item.last_name : '',
                    date_of_birth: item.date_of_birth && item.date_of_birth !== "null" ? moment.utc(item.date_of_birth).format("YYYY-MM-DD") : null,
                    country_code: item.country_code && item.country_code.value ? item.country_code.value : null, phone_number: item.phone_number, contact_number: item.contact_number ? item.contact_number : '',
                    email_address: item.email_address, student_usi: item.student_usi ? item.student_usi : '',
                    zone: item.zone, profile_pic: item.profile_pic,
                    visa_number: item.visa_number ? item.visa_number : '', visa_doc: item.visa_doc,
                    passport_number: item.passport_number,
                    middle_name: item.middle_name ? item.middle_name : '',
                    gender: item.gender && item.gender !== "null" ? { label: item.gender, value: item.gender } : null,
                    country_code: item.country_code && item.country_code !== "null" ? { label: item.country_code, value: item.country_code } : null,
                    phone_verified: item.phone_verified,
                    email_verified: item.email_verified,
                }

            } else {
                value = {
                    id: item.id, name: item.name ? item.name : '', last_name: item.last_name ? item.last_name : '',
                    date_of_birth: item.date_of_birth && item.date_of_birth !== "null" ? moment.utc(item.date_of_birth).format("YYYY-MM-DD") : null,
                    country_code: item.country_code && item.country_code.value ? item.country_code.value : null, phone_number: item.phone_number, contact_number: item.contact_number ? item.contact_number : '',
                    email_address: item.email_address, student_usi: item.student_usi ? item.student_usi : '',
                    zone: item.zone, visa_number: item.visa_number ? item.visa_number : '',
                    visa_doc: item.visa_doc, passport_number: item.passport_number,
                    middle_name: item.middle_name ? item.middle_name : '',
                    gender: item.gender && item.gender !== "null" ? { label: item.gender, value: item.gender } : null,
                    country_code: item.country_code && item.country_code !== "null" ? { label: item.country_code, value: item.country_code } : null,
                    phone_verified: item.phone_verified,
                    email_verified: item.email_verified,
                }
            }
        }
    }

    for (let key in value) {
        if (value[key] === "null") {
            value[key] = null;
        }
    }

    let initialValues = {
        ...value,
    }
    return {
        formValues: state.form && state.form.PesonalDetailsForm &&
            state.form.PesonalDetailsForm.values ? state.form.PesonalDetailsForm.values : {},
        isDirty: isDirty('PesonalDetailsForm')(state),
        profileComplete: state.profileManagement && state.profileManagement.profileComplete,
        profile: state.profileManagement && state.profileManagement.profile,
        initialValues: initialValues,
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'PesonalDetailsForm', validate: personalDetailsForm, enableReinitialize: true,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(PesonalDetailsForm)