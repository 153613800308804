import React, { Component } from "react";
import { Field } from "redux-form";
import CustomRadioGroup from "../../../../../components/common/new-core-ui/CustomRadioGroup";
import styles from "./PreAllocateStyles.module.css";
import { connect } from "react-redux";
import jobStyles from "../../../Job/JobStyle.module.css";
import { colorStylesMulti } from "../../../Job/SelectV2SingleStyle";
import { IconButton } from "@mui/material";
import { CloseCircleOutlined } from "@ant-design/icons";
import { CustomSingleSelect } from "../../../../../components/common/new-core-ui/CustomSingleSelect";

export class StaffAllocate extends Component {
  state = {
    serviceAgents: [],
    staff: [],
    selectedSA: null,
    searchString: "",
    saStaffs: []
  };

  componentDidMount() {
    const { service_agents } = this.props.staffList;
    this.setState({
      serviceAgents: service_agents.map((sa) => {
        let users = this.props.allocatedUsers.filter(user => user.org_id === sa.id).length;
        return {
          ...sa,
          label: <span><span>{sa.name}</span>{users > 0 ? <span className={styles.LabelBlue}> ({users})</span> : ''}</span>,
          value: sa.id,
          users: users
        }
      })
    });

    const staffUsers = [];

    // showing users from first service agents instead of all users
    let sa = service_agents[0];
    sa.site_supervisors.forEach((sup) =>
      staffUsers.push({
        ...sup,
        value: sup.user_name,
        label: sup.first_name,
        sa_id: sa.id,
        org_id: sa.id
      })
    );
    sa.staffs.forEach((st) =>
      staffUsers.push({ ...st, value: st.user_name, label: st.first_name, sa_id: sa.id, org_id: sa.id })
    );

    this.setState({ staff: [] });

    if (this.props.updating) {
      const { jobDocumentDetails, staffList } = this.props;

      let allocatedStaffUsernames = jobDocumentDetails.job_allocated_users.map((user) => user.user_name);
      allocatedStaffUsernames = [...new Set(allocatedStaffUsernames)];
      const selectedServiceAgent = staffList.service_agents.find((sa) =>
        [...sa.site_supervisors, ...sa.staffs].some((user) =>
          allocatedStaffUsernames.includes(user.user_name)
        )
      );
      let selectedKeys = []
      let users = [];
      jobDocumentDetails.job_allocated_users.filter(x => x.selected_for_job_doc).forEach(x => {
        if (selectedKeys.indexOf(x.user_name) === -1) {
          selectedKeys.push(x.user_name);
          users.push({ ...x, label: x.organisation_user && x.organisation_user.first_name ? x.organisation_user.first_name : x.user_name, value: x.user_name })
        }
      });
      if (selectedServiceAgent && selectedServiceAgent.id) {
        this.props.change("select_sa", selectedServiceAgent.id);
        this.handleSAChange(selectedServiceAgent.id, false);
        this.setState({ staff: users });
        this.props.change("selected_staffs", users);
        this.updateCount(selectedServiceAgent.id, users.length);
      } else {
        this.props.change("select_sa", null);
      }
    }
  }

  updateCount = (org_id, count = 0) => {
    const { service_agents } = this.props.staffList;
    this.setState({
      serviceAgents: service_agents.map((sa) => ({
        ...sa,
        label: <span><span>{sa.name}</span>{org_id === sa.id && count > 0 ? <span className={styles.LabelBlue}> ({count})</span> : ''}</span>,
        value: sa.id,
        users: count
      }))
    });
  }

  handleSAChange = (id, update = true) => {
    let serviceAgent = this.props.staffList.service_agents.find(sa => sa.id === id);
    let staffUsers = [];
    if (serviceAgent) {
      // set users from the selected service agent
      serviceAgent.site_supervisors.forEach(sup => {
        staffUsers.push({
          ...sup,
          value: sup.user_name,
          label: sup.first_name,
          sa_id: serviceAgent.id,
          org_id: serviceAgent.id
        })
      })
      serviceAgent.staffs.forEach((st) =>
        staffUsers.push({ ...st, value: st.user_name, label: st.first_name, sa_id: serviceAgent.id, org_id: serviceAgent.id })
      );
    }
    this.setState({ staff: [], selectedSA: id, saStaffs: staffUsers });
    this.updateCount(id, 0);
    if (update) {
      this.props.change("selected_staffs", []);
    }
  }

  onItemRemove = (v) => {
    let users = this.props.formValues.selected_staffs ? this.props.formValues.selected_staffs.filter(x => x.value !== v.value) : [];
    this.props.change("selected_staffs", users);
    this.setState({ staff: users });
    this.updateCount(this.props.formValues.select_sa, users.length);
  }

  onChange = (v) => {
    this.props.change("selected_staffs", v);
    this.setState({ staff: v });
    this.updateCount(this.props.formValues.select_sa, v.length);
  }

  render() {
    return (
      <div className={styles.DocsContainer}>
        <div className={`${styles.StaffFlow}`}>
          <div className="container-fluid py-0">
            <div className="row py-0">
              <div className="col-lg-3 col-md-12 col-sm-12 px-2">
                <div className={jobStyles.SectionHeading}>Allocate Contractor</div>
                <div>
                  <Field
                    name="select_sa"
                    disabled={false}
                    options={this.state.serviceAgents}
                    itemClassName="cs-radio-v2"
                    component={CustomRadioGroup}
                    onChange={(e) => this.handleSAChange(e.target.value)}
                  />
                  <div className={`${jobStyles.CommonLabel} mt-2`}>Select Staff</div>
                  <Field
                    name="selected_staffs"
                    options={this.state.saStaffs.map((v) => ({ ...v, value: v.user_name }))}
                    component={CustomSingleSelect}
                    isMulti
                    otherProps={{
                      isDisabled: !this.props.formValues || !this.props.formValues.select_sa,
                      styles: colorStylesMulti,
                      closeMenuOnSelect: false,
                      hideSelectedOptions: false,
                      menuPortalTarget: document.body,
                      onChange: this.onChange
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 px-2">
                <div className={jobStyles.CommonLabel}>
                  Allocated Staff
                </div>
                <div>
                  <div className="container-fluid py-0 m-0">
                    <div className="row py-0" style={{ gap: "5px 0px" }}>
                      {this.props.formValues && this.props.formValues.selected_staffs && this.props.formValues.selected_staffs.length ? this.props.formValues.selected_staffs.map((staffItem) => (
                        <div className="col-sm-6 col-md-4 col-lg-3 px-0" key={staffItem.value}>
                          <div>
                            <div className="d-flex align-items-center justify-content-start" style={{ gap: "5px" }}>
                              <div style={{ flex: "0 0 auto" }}>
                                <IconButton onClick={() => this.onItemRemove(staffItem)} size="small" style={{ height: "15px", width: "15px" }} color="error">
                                  <CloseCircleOutlined />
                                </IconButton>
                              </div>
                              <div style={{ flex: "1 1 auto" }}>{staffItem.label}</div>
                            </div>
                          </div>
                        </div>
                      )) : (
                        <div className="text-center py-2" style={{ flex: 1 }}>
                          <div className={styles.LabelRed}>No Staff Allocated / Available.</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  staffList: state.JobDocument.staffList,
  chemicals: state.JobDocument.chemicals,
  jobDetails: state.JobDocument.jobDetails,
  certificates: state.JobDocument.certificates,
  licenseTypes: state.JobDocument.licenseTypes,
  jobDocumentDetails: state.JobDocument.jobDocumentDetails,
  jobDocumentCreatorDetails: state.JobDocument.jobDocumentCreatorDetails,
  initialValues: state.JobDocument.initialValues,
  allocatedUsers: state.JobDocument.allocatedUsers,
  allocatedTasks: state.JobDocument.allocatedTasks,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StaffAllocate);
